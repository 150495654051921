import React from 'react';
import heroThumb from '../../assets/images/hero-thumb-7.png';
import CurrentJobsHero from "./CurrentJobsHero";

function HomeNigeria({jobs}) {
    return (
        <>
            <section className="appie-hero-area pb-0 mt-50">
                <div className="container">
                    <div className="row align-items-start">

                        <div className="col-12">
                            <div className="appie-hero-content appie-hero-content-6">
                                <h1 className="appie-title">Pick any task and start earning.</h1>
                                <div className='row'>
                                    <p className='col-12 col-lg-8'>
                                        The Marketplace platform to earn money with your skills, find, buy and sell professional services.
                                    </p>
                                    <ul className='col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end'>
                                        <li className=''>
                                            <a className="item-2" target='_blank'  href={process.env.REACT_APP_APPLE_APP}>
                                                <i className="fab fa-apple"></i>
                                                <span>
                                                    Available on the <span>App Store</span>
                                                </span>
                                            </a>
                                        </li>
                                        <li className=''>
                                            <a className="item-2" target='_blank' href={process.env.REACT_APP_ANDROID_APP}>
                                                <i className="fab fa-google-play"></i>
                                                <span>
                                                    Available on the <span>Google Play</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-lg-6">
                            <CurrentJobsHero jobs={jobs} />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeNigeria;
