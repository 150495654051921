import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Error from './components/Error';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';
import HomeOne from './components/HomeOne';
import HomeSeven from './components/HomeSeven';
import HomeSix from './components/HomeSix';
import News from './components/News';
import SingleNews from './components/News/SingleNews';
import Service from './components/Service';
import UseCases from './components/UseCases';

import Privacy from './components/Service/Privacy';
import Terms from './components/Service/Terms';
import FAQ from './components/FAQ/Index';
import AppDownload from './components/AppDownload/AppDownload';

function Routes() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  return (
    <>
      {loading && (
        <div className={`appie-loader ${loading ? 'active' : ''}`}>
          <Loader />
        </div>
      )}
      <div className={`appie-visible ${loading === false ? 'active' : ''}`}>
        <ScrollToTop>
          <Switch>
            {/*<Route exact path="/" component={HomeSix} />*/}

            <Route exact path="/" component={HomeOne} />
            <Route exact path="/news" component={News} />
            <Route exact path="/blog" component={News} />
            <Route exact path="/use-cases" component={UseCases} />
            <Route exact path="/news/single-news" component={SingleNews} />
            <Route exact path="/service" component={Service} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/error" component={Error} />
            <Route exact path="/app" component={AppDownload} />
            <Route component={Error} />
          </Switch>
        </ScrollToTop>
      </div>
    </>
  );
}

export default Routes;
