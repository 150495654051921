import React, { useEffect, useState } from 'react';
import blogOne from '../../assets/images/blog/1.jpg';
import BlogData from '../../Services/BlogData';

function Blogs({pathname}) {
    const [blogs, setBlogs] = useState([])

    useEffect(()=>{
        BlogData().then(res => {
            setBlogs(res.data)
        }).catch(err => {
            console.log('Error loading blogdata', err)
        })
    },[])

    return (
        <>
            <div className="row">
                { pathname == '/' ? // ON HOME PAGE LIMIT TO SIX(6) BLOGS
                    blogs?.blogdata?.map((i, index)=> {
                        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        var postDt  = new Date(i.post_date).toLocaleDateString("en-US", options);
                        var blgImg = i.meta_value != null ? `${blogs?.blogconfig?.media_url}/${i.meta_value}` : blogOne;
                        if(index < 6){
                        return (
                        <div key={i.id} className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blgImg} alt={i.post_title} />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>{postDt}</li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                        <a href={i.guid}>
                                            {i.post_title}
                                        </a>
                                    </h3>
                                    <a href={i.guid}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        )
                        }
                    })
                    : // ON OTHER PAGES SHOW ALL BLOG
                    blogs?.blogdata?.map((i, index)=> {
                        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        var postDt  = new Date(i.post_date).toLocaleDateString("en-US", options);
                        var blgImg = i.meta_value != null ? `${blogs?.blogconfig?.media_url}/${i.meta_value}` : blogOne;
                        return (
                        <div key={i.id} className="col-lg-4 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blgImg} alt={i.post_title} />
                                </div>
                                <div className="content">
                                    <div className="blog-meta">
                                        <ul>
                                            <li>{postDt}</li>
                                        </ul>
                                    </div>
                                    <h3 className="title">
                                        <a href={i.guid}>
                                            {i.post_title}
                                        </a>
                                    </h3>
                                    <a href={i.guid}>
                                        Learn More <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        )
                    })
                }
            </div>

        </>
    );
}

export default Blogs;
