import React, { Component, useEffect, useState } from 'react';
import blogOne from '../../assets/images/blog-1.jpg';
import blogTwo from '../../assets/images/blog-2.jpg';
import blogThree from '../../assets/images/blog-3.jpg';
// import JobsData from '../../Services/JobsData';
import getConfig from './../../Config/config'
import CountDownTimer from '../Helper/CountDownTimer';

let RecentJobsOne = ({jobs}) => {


    var site = getConfig()[0];

    var dashUrl = process.env.REACT_APP_DASH_URL;

    function titleLen(title){
        let maxl = 45;
        title.replace('/', ' ');
        title.replace('www.', '');
        title.replace('.com', '');
        title.replace('http//', '');

        return (title.length > maxl)? title.substring(0,maxl-2)+'...': title;
    }

    return (
        <>
        {jobs.length < 1 ?
        null
        :
        <section className="appie-blog-area pt-50 pb-50">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="appie-section-title text-left">
                            <h3 className="appie-title">Current Projects</h3>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {
                        jobs.map((i, index) => {
                            var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                            var postDt  = new Date(i.expire).toLocaleDateString("en-US", options); 
                            if(index < 24){
                                return (
                                <div className="col-md-6 col-xl-3">
                                    <div
                                        className="appie-single-service container-fluid mt-30 wow animated fadeInUp boxBorder d-flex align-items-center"
                                        data-wow-duration="3000ms"
                                        data-wow-delay="200ms"
                                    >
                                        <div className="content d-flex flex-column justify-content-between" style={{height: '250px', width: '100%'}}>
                                            <div className="titleBox">
                                                <h3 className="title">
                                                    <a href={dashUrl}>
                                                    <span className='font_black'>{titleLen(i.title)} </span>   
                                                    </a>
                                                </h3>
                                            </div>
                                            <div className='p-0 container-fluid'>
                                                <div><hr /></div>
                                                <div className="blog-meta">
                                                    <ul>
                                                        <li className="expire">
                                                            <a href={dashUrl} className='d-block'>
                                                                <div className='font_red d-flex align-items-start'> 
                                                                    <div className='pr-2'>Expires :</div>
                                                                    <CountDownTimer targetDate={postDt}/>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className='lmoreTxt d-flex justify-content-end align-items-center'>
                                                    <a href={process.env.REACT_APP_DASH_URL_LOGIN}>
                                                    Learn More <i className="fal fa-arrow-right" />
                                                </a>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            }

                        })
                    }


                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-blog-item mt-30 wow animated fadeInUp"
                            data-wow-duration="3000ms"
                            data-wow-delay="600ms"
                        >

                            <div className="content">

                                <h3 className="title">
                                    <a href={dashUrl}>
                                        Find more opportunities at our marketplace.
                                    </a>
                                </h3>
                                <a href="https://dashboard.wrenchboard.com/login">
                                    Login now <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        }
        </>
    );
}

export default RecentJobsOne;