import React from 'react';
import getConfig from './../../Config/config'
import ContactData from '../../Services/ContactData';


function Forms() {

    var site = getConfig()[0];

    function handleSubmit(e) {
        e.preventDefault();
      //  console.log('You clicked submit.');
      //  console.log(e);
       // debugger;
        const firstname = e.target['f-name'].value;
        const lastname = e.target['l-name'].value;
        const email = e.target['email'].value;
        const phone = e.target['phone'].value;
        const subject = e.target['subject'].value;
        const message =  e.target['message'].value;
        const terms =   e.target['terms-conditions'].checked;
//alert(terms);

        var callData = [{
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "phone": phone,
            "subject": subject,
            "message": message,
            "channel": 'WEB'
        }];

       const callRet = ContactData(callData);
       console.log('You clicked submit========> '+ callRet);
    }



    return (
        <>
            <section className="contact-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact--info-area">
                                <h3>Get in touch</h3>
                                <p>Looking for help? Fill the form and start a new discussion.</p>
                                <div className="single-info">
                                    <h5>Headquaters</h5>
                                    <p>
                                        <i className="fal fa-home"></i>
                                        {process.env.REACT_APP_SUPPORT_NG_ADDRESS}
                                    </p>

                                    <p>
                                        <i className="fal fa-home"></i>
                                          {process.env.REACT_APP_SUPPORT_US_ADDRESS}
                                    </p>

                                </div>
                                <div className="single-info">
                                    <h5>Phone</h5>
                                    <p>
                                        <i className="fal fa-phone"></i>
                                        {process.env.REACT_APP_SUPPORT_PHONE_NG}
                                        <br />
                                        {process.env.REACT_APP_SUPPORT_PHONE}
                                    </p>
                                </div>
                                <div className="single-info">
                                    <h5>Support</h5>
                                    <p>
                                        <i className="fal fa-envelope"></i>
                                        {process.env.REACT_APP_SUPPORT_EMAIL}
                                       
                                    </p>
                                </div>
                                <div className="ab-social">
                                    <h5>Follow Us</h5>
                                    <a className="fac" href={process.env.REACT_APP_FACEBOOK_LINK}>
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="twi" href={process.env.REACT_APP_TWITTER_LINK}>
                                        <i className="fab fa-x-twitter"></i>
                                    </a>
                                    <a className="you" href="#">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    <a className="lin" href={process.env.REACT_APP_LINKEDIN_LINK}>
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="contact-form">
                                <h4>Let’s Connect</h4>
                                <form onSubmit={handleSubmit} className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="f-name" placeholder="First Name"  maxLength={15} />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="l-name" placeholder="Last Name"  maxLength={15} />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email Address" 
                                            maxLength={35} 
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            name="phone"
                                            placeholder="Phone Number" 
                                            maxLength={15} 
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <input type="text" name="subject" placeholder="Subject"  maxLength={35}  />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea
                                            name="message"
                                            placeholder="How can we help?"
                                        ></textarea>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="condition-check">
                                            <input id="terms-conditions" name="terms-conditions" type="checkbox" />
                                            <label htmlFor="terms-conditions">
                                                I agree to the <a href="#">Terms & Conditions</a>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <input type="submit" name="submit" value="Send Message" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="bisylms-map">
                <iframe
                    title="map"
                    src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;coord=33.8573837,-84.4766235&amp;q=Cumberland+Pkwy+SE,+Atlanta,+GA+30339&amp;ie=UTF8&amp;t=p&amp;z=16&amp;iwloc=B&amp;output=embed"
                ></iframe>
            </div>
        </>
    );
}

export default Forms;
