import React from 'react';
import IconOne from '../../assets/images/icon/account_login.png';
import IconTwo from '../../assets/images/icon/task.png';
import IconThree from '../../assets/images/icon/target.png';
import IconFour from '../../assets/images/icon/reward.png';

// import FirstSlide from '../../assets/images/icon/Untitledxyz.png';


function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-90 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                               Start getting paid in easy steps.
                            </h3>
                          
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <img src={IconOne} alt="" /> 
                            </div>
                            <h4 className="appie-title">Free Account</h4>
                            <p>Join WrenchBoard.<span>Create an account for Income.</span></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Find Task </h4>
                            <p>Build a Self-Portfolio <span>with sole purpose to Cash-Out.</span></p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
>
                            <div className="icon">
                                <img src={IconThree} alt="" />   
                            </div>
                            <h4 className="appie-title">Complete</h4>
                            <p>Organize and Manage <span>your teams efficiently. </span> Manage your workgroup.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <img src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">Get Paid</h4>
                            <p>Start <span>Boosting your Income </span> By earning Cash for your Time and Skills.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
