import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/wrenchboard.png';
import getConfig from './../../Config/config'

function FooterHomeOne({ className }) {
  var site = getConfig()[0];
  let newDate = new Date().getFullYear()

  return (
    <>
      <section className={`appie-footer-area ${className || ''}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about-widget">
                <div className="logo">
                  <a href="#">
                    <img src={logo} alt="WrenchBoard" />
                  </a>
                </div>
                <p>
                  {`It is simple. You can do something that somebody is ready to pay you for. WrenchBoard is the platform to connect you with earning opportunities. `}
                </p>
                <a href="/service">
                  Read More <i className="fal fa-arrow-right" />
                </a>
                <div className="social mt-30">
                  <ul>
                    <li>
                      <a href={site.facebook_link}>
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a className="twi" href={process.env.REACT_APP_TWITTER_LINK}>
                        <i className="fab fa-x-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Company</h4>
                <ul>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/service">Our Services</Link>
                  </li>
                  <li>
                    <a href="/use-cases">Use Cases</a>
                  </li>
                  <li>
                      <a target='_blank' href={process.env.REACT_APP_AGENT_LINK}>Agent</a>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-navigation">
                <h4 className="title">Support</h4>
                <ul>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <a href="/faq">Faqs</a>
                  </li>
                  <li>
                    <a href="https://blog.wrenchboard.com/resources/">Resources</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/terms">Terms of use</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget-info">
                <h4 className="title">Get In Touch</h4>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fal fa-envelope" /> {site.support_email}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-phone" />  404-855-7966
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fal fa-map-marker-alt" />Atlanta, GA 30339
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-copyright d-flex align-items-center justify-content-between pt-2">
                <div className="apps-download-btn">
                  <ul>
                    <li>
                      <a href={process.env.APPLE_APP}>
                        <i className="fab fa-apple" /> Download for iOS
                      </a>
                    </li>
                    <li>
                      <a className="item-2" href={process.env.REACT_APP_ANDROID_APP}>
                        <i className="fab fa-google-play" /> Download for
                        Android
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="copyright-text">
                  <p>Copyright © {newDate} WrenchBoard. All rights reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FooterHomeOne;
