import React, { useEffect, useState } from 'react';
import ServiceSideMenu from './ServiceSideMenu';
import {Link} from 'react-router-dom'
import FaqData from '../../Services/FaqData';
import Arrow from '../../assets/images/arrow-down.png'

function FAQService() {
    const [isOpen, setOpen] = React.useState({type: 'faq0'});
    const accordionHandler = (name) => {
      setOpen(prev => {
        if(prev.type == name){
            return {type: ''}
        }else {
            return {type: name}
        }
      });
    };
    let [faq, setFaq] = useState([])

    useEffect(()=>{
        FaqData().then(res => {
            setFaq(res.data.result_list)
        }).catch(err => {
            console.log('You got an error ========> '+ err)
        })
    },[])

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <ServiceSideMenu />
                        </div>
                        <div className="col-lg-8 accordion-wrapper">
                            <div className="p-3 service-details-content accordion-con">
                                <div className="container-fluid">
                                  <div className='content'>
                                    <h3 className='p-3 text-center title'>Frequently asked questions</h3>
                                  </div>
                                </div>
                                <div className='row'>
                                    <div class="col-12 col-lg-6 accordion" id="accordionExample">
                                        {faq.map((item, index)=>{
                                            if(index%2 == 0 && item.public != 0){
                                                return(
                                                <div key={index} className='my-3'>
                                                    <Accordion name={`faq${index}`} datas={item} accordionHandler={accordionHandler} isOpen={isOpen} />
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    <div class="col-12 col-lg-6 accordion" id="accordionExample">
                                        {faq.map((item, index)=>{
                                            if(index%2 != 0 && item.public != 0){
                                                return(
                                                <div key={index} className='my-3'>
                                                    <Accordion name={`faq${index}`} datas={item} accordionHandler={accordionHandler} isOpen={isOpen} />
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                                <div className='container-fluid text-center'>
                                    <p className='my-4'>Can't find an answer <Link to='/contact'>contact us</Link> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FAQService;

export function Accordion({ datas, name, accordionHandler, isOpen }) {
    // const [isOpen, setOpen] = React.useState(false);
    // const accordionHandler = () => {
    //   setOpen(!isOpen);
    // };
    return (
      <>
        <div className="accordion-item">
          <div
            className="accordion-title-bar container-fluid"
            onClick={()=>{accordionHandler(name)}}
          >
            <div className="accordion-title">
              <p className="">
                {datas.title}
              </p>
            </div>
            <div className="accordion-title-icon">
              <span className={`horizontal ${isOpen.type == name ? 'vertical' : ''}`}>
                <img className="" src={Arrow} />
              </span>
            </div>
          </div>
          <div className={`${isOpen.type == name ? "accordion-body-show" : "accordion-body-hide"}`}>
            <div className="accordion-body-content" style={{display: 'flex', padding: '10px'}}>
              <div className="rounded-[28px]" style={{width: '3px', backgroundColor: 'skyblue'}}></div>
              <div className="flex-1">
                <p className="" style={{padding: '0px 10px', letterSpacing:'.5px'}}>
                  {datas.msg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }