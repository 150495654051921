import React from 'react';
import thumb from '../../assets/images/service-page.jpg';
import ServiceSideMenu from './ServiceSideMenu';
import getConfig from './../../Config/config'

function ServiceTopart() {
    var site = getConfig()[0];

    return (
        <>
            <section className="appie-service-details-area pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <ServiceSideMenu />
                        </div>
                        <div className="col-lg-8">
                            <div className="service-details-content">
                                <div className="content">
                                    <h3 className="title">What we do.</h3>
                                    <p>
                                    WrenchBoard is where you set goals and reward accomplishments or find somebody to perform a needed task.
                                    </p>
                                    {/*<hr />*/}
                                    {/*<p>*/}
                                    {/*    /!*An open-platform for you to build your self - image, skills set, branded personality,*!/*/}
                                    {/*    /!*carve a network and ofcourse an income flow with ease and satisfaction of self - actualization and accomplishments.*!/*/}
                                    {/*</p>*/}
                                    {/*<p>*/}
                                    {/*    A major task done by skilled professional with personal touch and completed within the twinkling of an eye in specified time-frame.*/}
                                    {/*</p>*/}
                                    <hr />
                                    <p>
                                    WrenchBoard is the marketplace that allows users to raise their hands and gives you the power to select who you want to perform your task.
                                    </p>
                                    <hr />
                                    <p>
                                    Our commitment to quality ensures that WrenchBoard.com remains a trusted platform for reliable transaction and redspectable partnerships.
                                    </p>
                                    <hr />
                                    Do more at < a href ={process.env.REACT_APP_DASH_URL_LOGIN} >WrenchBoard</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServiceTopart;
