import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import StickyMenu from '../../lib/StickyMenu';
import BackToTop from '../BackToTop';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import ProjectHomeOne from '../HomeOne/ProjectHomeOne';
import Drawer from '../Mobile/Drawer';
import DetailsService from './DetailsService';
import HeaderService from './HeaderService';
import HeroService from './HeroService';
import HeroNews from '../News/HeroNews';
import MissionStatement from "./MissionStatement";
import ServiceTopart from "./ServiceTopart";

function Service() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderService action={drawerAction.toggle} />
            {/* <HeroService title="Our Service to you" /> */}
            <HeroNews
                title="Services"
                breadcrumb={[
                    { link: '/', title: 'Home' },
                    { link: '/service', title: 'Service' },
                ]}
            />
            <ServiceTopart />
            <MissionStatement />
            {/*<DetailsService />*/}
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default Service;
