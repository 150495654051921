import React, { useState } from 'react';
import blogOne from '../../assets/images/blog-1.jpg';
import blogTwo from '../../assets/images/blog-2.jpg';
import blogThree from '../../assets/images/blog-3.jpg';
import Blogs from '../News/Blogs';


function BlogHomeOne() {
    return (
        <>
            <section className="appie-blog-area pt-90 pb-95">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Our latest blog posts</h3>
                            </div>
                        </div>
                    </div>
                    <Blogs pathname='/' />
                </div>
            </section>
        </>
    );
}

export default BlogHomeOne;
