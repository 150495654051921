import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import thumb from '../../assets/images/onlineregistration.png';
// import thumb22 from '../../assets/images/marketplace.png';
// import thumb3 from '../../assets/images/product-listing-on-online-marketplace.png';
// import thumb4 from '../../assets/images/flying.png';
import freeAccount from '../../assets/images/free_account.png';
import marketPlace from '../../assets/images/market_place.png';
import task from '../../assets/images/task.png';
import getPaid from '../../assets/images/get_paid.png';

// import taskThumbnail from '../../assets/images/task-thumb.png'
// import marketThumbnail from '../../assets/images/market-thumb.png'
// import getPaidThumbnail from '../../assets/images/getpaid-thumb.png'
// import accountThumbnail from '../../assets/images/account-thumb.png'


import getConfig from './../../Config/config'

function FeaturesHomeOne({ className }) {
    var site = getConfig()[0];
    return (
        <></>
        // <section className={`appie-service-area features-section pt-50 pb-100 ${className}`} id="service">
        //     <div className="container">
        //         <div className="row">
        //             <div className="col-xl-3 col-md-6 mb-50">
        //                 <div
        //                     className="appie-features-content wow animated fadeInRight"
        //                     data-wow-duration="2000ms"
        //                     data-wow-delay="600ms"
        //                 >
        //                     <div className='text-center'>
        //                         <img className='pb-2' src={accountThumbnail} alt='image thumbnail' />
        //                     </div>
                            
        //                     <h3 className="title">
        //                         Create free account.
        //                     </h3>
        //                     <p>
        //                         If you are either currently employed, unemployed or unsatisfied with your job or want extra cash on the side, consider WrenchBoard as a reliable partner to connect you with new opportunities.
        //                     </p>
        //                     <Link className="main-btn" to="/about-us">
        //                         Learn More
        //                     </Link>
        //                     {/* <div
        //                         className="appie-features-thumb text-center wow animated fadeInUp"
        //                         data-wow-duration="2000ms"
        //                         data-wow-delay="200ms"
        //                     >
        //                         <img className='p-3' src={freeAccount} alt="" />
        //                     </div> */}
        //                 </div>
        //             </div>
        //             <div className="col-xl-3 col-md-6 mb-50">
        //                 <div
        //                     className="appie-features-content animated fadeInRight"
        //                     data-wow-duration="2000ms"
        //                     data-wow-delay="600ms"
        //                 >
        //                     <div className='text-center'>
        //                         <img className='pb-2' src={marketThumbnail} alt='image thumbnail' />
        //                     </div>
                            
        //                     <h3 className="title">
        //                         Browse Marketplace
        //                     </h3>
        //                     <p>
        //                     WrenchBoard platform connects you with quality service that need articles writing, blog management, photography, product testing research and surveys and more to deliver your project with ease.
        //                     </p>
        //                     <a className="main-btn" href={process.env.REACT_APP_DASH_URL_LOGIN}>
        //                         Learn More
        //                     </a>
        //                 </div>
        //             </div>
        //             <div className="col-xl-3 col-md-6 mb-50">
        //                 <div
        //                     className="appie-features-content animated fadeInRight"
        //                     data-wow-duration="2000ms"
        //                     data-wow-delay="600ms"
        //                 >
        //                     <div className='text-center'>
        //                         <img className='pb-2' src={taskThumbnail} alt='image thumbnail' />
        //                     </div>
                            
        //                     <h3 className="title">
        //                     Complete Task
        //                     </h3>
        //                     <p>
        //                         Cash in the Pocket with WrenchBoard as a reliable partner to connect you with new opportunities.Track, Organize your Portfolio and Manage your Skills, preferred workgroup and teams efficiently.
        //                     </p>
        //                     <a className="main-btn" href={process.env.REACT_APP_DASH_URL_LOGIN}>
        //                         Learn More
        //                     </a>
        //                 </div>
        //             </div>
        //             <div className="col-xl-3 col-md-6 mb-50">
        //                 <div
        //                     className="appie-features-content animated fadeInRight"
        //                     data-wow-duration="2000ms"
        //                     data-wow-delay="600ms"
        //                 >
        //                     <div className='text-center'>
        //                         <img className='pb-2' src={getPaidThumbnail} alt='image thumbnail' />
        //                     </div>
                            
        //                     <h3 className="title">
        //                         Get Paid
        //                     </h3>
        //                     <p>
        //                         Get paid Instantly for completed task using WrenchBoard. Achieve your short-term goals or long-term income by Connecting with WrenchBoard. Complete access to your earnings.
        //                     </p>
        //                     <a className="main-btn" href={process.env.REACT_APP_DASH_URL_LOGIN}>
        //                         Learn More
        //                     </a>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     {/* <hr className='mt-50'/> */}
        // </section>
    );
}

export default FeaturesHomeOne;
