
import React, { useState } from 'react';
import CounterUpCom from '../../lib/CounterUpCom';
import PopupVideo from '../PopupVideo';

function MissionStatement() {
    const [showQuestion, setQuestion] = useState(1);
    const [showVideo, setVideoValue] = useState(false);
    const openQuestion = (e, value) => {
        e.preventDefault();
        setQuestion(value);
    };

    const ourMissions = [
        {
            id: 1,
            title: "Rewards Family and Personal Goals",
            content: "We will be the best platform for you to set a rewardable goal for you, your family, and others",
        },
        {
            id: 2,
            title: "Expand Earning Opportunities",
            content: "We will always present opportunities equally",
        },
        {
            id: 3,
            title: "Exhibit  your Capabilities",
            content: "We will let your ability shine to opportunities",
        },
        {
            id: 4,
            title: "Get your work done",
            content: "For other tasks you need to get done, we will be there for smooth engagement",
        }
    ];

    const handleShowVideo = (e) => {
        e.preventDefault();
        setVideoValue(!showVideo);
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                    handler={(e) => handleShowVideo(e)}
                />
            )}
            <div className="appie-faq-8-area pt-100 pb-100" id="counter">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Our Mission.</h3>
                                <p>
                                    Our mission at WrenchBoard is to empower individuals, families and communities through :
                                </p>
                            </div>
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp wow fadeIn faq-accrodion"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    {
                                         ourMissions.map((item) => (
                                                <div
                                                    className={`accrodion ${
                                                        showQuestion === item.id ? 'active' : ''
                                                    }`}
                                                    onClick={(e) => openQuestion(e, item.id)}
                                                >
                                                    <div className="accrodion-inner">
                                                        <div className="accrodion-title">
                                                            <h4>{item.title}</h4>
                                                        </div>
                                                        <div
                                                            className="accrodion-content"
                                                            style={{
                                                                display: showQuestion === item.id ? 'block' : 'none',
                                                            }}
                                                        >
                                                            <div className="inner">
                                                                <p>
                                                                    {item.content}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mission-side-box"></div>
            </div>
        </>
    );
}

export default MissionStatement;

