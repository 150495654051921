import React from 'react';
import getConfig from './../../Config/config'

function ServiceSideMenu() {
    var site = getConfig()[0];
    return (
        <>
            <div className="service-details-sidebar mr-50">

                <div className="service-download-widget">
                    <a href="/">
                        <i className="fal fa-download"></i>
                        <span>Home</span>
                    </a>
                </div>

                <div className="service-download-widget">
                    <a href="/about-us">
                        <i className="fal fa-download"></i>
                        <span>About us</span>
                    </a>
                </div>
                <div className="service-download-widget">
                    <a href="/use-cases">
                        <i className="fal fa-file-pdf"></i>
                        <span>Use Cases</span>
                    </a>
                </div>



                <div className="service-download-widget">
                    <a href="/privacy">
                        <i className="fal fa-download"></i>
                        <span>Privacy Policy</span>
                    </a>
                </div>
                <div className="service-download-widget">
                    <a href="/terms">
                        <i className="fal fa-file-pdf"></i>
                        <span>Terms of use</span>
                    </a>
                </div>

                <div className="service-category-widget">
                    <ul>
                        <li>
                            Get WrenchBoard App
                        </li>

                        <li>
                            <a href={site.apple_app}>
                                <i className="fab fa-apple" /> Download for iOS
                            </a>
                        </li>
                        <li>
                            <a className="item-2" href={process.env.REACT_APP_ANDROID_APP}>
                                <i className="fab fa-google-play" /> Download for
                                Android
                            </a>
                        </li>
                    </ul>
                </div>

            </div>


        </>
    );
}

export default ServiceSideMenu;
