import Axios from 'axios';
import getConfig from './../Config/config'

async function ContactData(callData) {
    // debugger;
    var site = getConfig()[0];
    let response = await Axios.post(`${process.env.REACT_APP_AUX_ENDPOINT}/sitecontact`, callData);
    return response.data.result;
}

export default ContactData;