import React, { createContext, useContext, useEffect, useState } from "react";


import io from "socket.io-client";

let SocketIOContext = createContext({})

export default function SocketIOContextProvider({children}) {

    const socket = io.connect(process.env.REACT_APP_PRIMARY_SOCKET); 

    // //Room State
    // const [room, setRoom] = useState("");

    // // Messages States
    // const [message, setMessage] = useState("");
    const [socketMsgReceived, setSocketMsgReceived] = useState('');

    const [updateMarket, setUpdateMarket] = useState(false)

    const joinRoom = (room) => {
        if (room !== "") {
        socket.emit("join_room", room);
        }
    };
    
    useEffect(() => {
        socket.on("received_refreshmarket_jobs", (data) => {
            setSocketMsgReceived(data?.message);
            setUpdateMarket(prev => !prev)
        });
    }, [socket]);

    let values = {
        socket,
        joinRoom,
        setSocketMsgReceived,
        socketMsgReceived,
        updateMarket,
    }

  return (
    <SocketIOContext.Provider value={values}>
        {children}
    </SocketIOContext.Provider>
  )
}


export const SocketValues = () => {
    return useContext(SocketIOContext)
}
