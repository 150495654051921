import React, { useEffect, useState } from 'react';

const CountDownTimer = ({ targetDate, aboutToExpire=false }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  },[]);

  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };

  const { days, hours, minutes, seconds } = timeLeft;
  
  const newHours = hours+days*24

  if (!days && !hours && !minutes && !seconds) {
    return <span>{targetDate}</span>;
  }

  return (
    <div>
        <div>{formatTime(newHours)} : {formatTime(minutes)} : {formatTime(seconds)}</div>
        <div>Hrs Min Sec</div>
        {/* <span className='d-flex flex-column count-timer'>
            <span>{formatTime(days)}</span>
            <span>day(s)</span>
        </span> */}
        {/* <span className='d-flex flex-column count-timer'>
            <span>{formatTime(newHours)}</span>
            <span>hr(s)</span>
        </span>
        <span className='p-3'>:</span>
        <span className='d-flex flex-column count-timer'>
            <span>{formatTime(minutes)}</span>
            <span>min(s)</span>
        </span>
        <span className='p-3'>:</span>
        <span className='d-flex flex-column count-timer'>
            <span>{formatTime(seconds)}</span>
            <span>sec(s)</span>
        </span> */}
    </div>
  );
};

export default CountDownTimer;

{/* <span>{days && <span>{formatTime(days)} days </span>}</span> */}
{/* <span>{hours && <span>{formatTime(hours)} hours </span>}</span> */}
{/* <span>{minutes && <span>{formatTime(minutes)} minutes </span>}</span> */}
{/* <span>{seconds && <span>{formatTime(seconds)} seconds </span>}</span> */}