import React from 'react';

function getConfig() {
    return [

        {
            "site_name" : 'WrenchBoard',
            "dash_url": 'https://dashboard.wrenchboard.com',
            "dash_url_login": "https://dashboard.wrenchboard.com/login",
            "dash_url_signup": "https://dashboard.wrenchboard.com/signup",
            "android_app": 'https://play.google.com/store/apps/details?id=com.wrenchboard.users',
            "apple_app": 'https://itunes.apple.com/us/app/wrenchboard/id1435718367?ls=1&mt=8',
            "facebook_link": 'https://www.facebook.com/wrenchboard',
            "twitter_link": 'https://twitter.com/wrenchboard/',
            "linked_link": "https://www.linkedin.com/company/wrenchboard/",
            "support_email": 'support@wrenchboard.com',
            "support_phone": '404 855-7966',
            "support_phone_ng": '(+420) 336 476 328',
            "support_us_address": 'Cumberland Pkwy, Atlanta GA 30339',
            "support_ng_address": 'Saka Tinubu Street, Victoria Island Lagos, Nigeria',
            "dummy": "2018-06-25T18:54:22.000Z",
            "user_service_endpoint": process.env.REACT_APP_AUX_ENDPOINT,
        }
    ];

}

export default getConfig;