import React from 'react';
import blogImg1 from '../../assets/images/blog/1.jpg';
import UseCaseData from '../../Services/UseCaseData';
import getConfig from './../../Config/config'


function UseCase() {
    var site = getConfig()[0];

    var UseCaseDataResult = UseCaseData();

    //C:\WORKS\WrenchBoardMainSite\src\assets\images\wrechboard-sample.jpg

    return (
        <>
            <div className="row">
                {
                    UseCaseDataResult.map(i => {
                        var blgImg = i.meta_value != null ? "/images/" + i.meta_value : blogImg1;

                        console.log("AA", blgImg);
                      //  blgImg = blogImg1;
                      //  console.log("BB", blgImg);

                        return (
                            <div className="col-lg-4">
                                <div className="post-item-1">

                                    {<img src={blgImg} alt={i.title} />}
                                    {/* <img  src={`../assets/images/${i.meta_value}`} /> */}
                                    <div className="b-post-details">

                                        <h3>
                                            <a href={process.env.REACT_APP_DASH_URL_LOGIN}>
                                                {i.title}
                                            </a>
                                        </h3>
                                        <a className="read-more" href={process.env.REACT_APP_DASH_URL_LOGIN}>
                                            Learn more<i className="fal fa-arrow-right"></i>
                                        </a>
                                    </div>

                                </div>
                            </div>

                        )

                    })
                }
            </div>

            {/*
            <div className="row">
                <div className="col-lg-12">
                    <div className="bisylms-pagination">
                        <span className="current">01</span>
                        <a href="#">02</a>
                        <a className="next" href="#">
                            next<i className="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
         */}

        </>
    );
}

export default UseCase;
