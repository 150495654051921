import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/service">Services</Link>
                </li>
                {/* <li>
                    <a href="#">
                        Resources <i className="fal fa-angle-down" />
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <Link to="/about-us">About</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                            <Link to="/faq">FAQs</Link>
                        </li>
                    </ul>
                </li> */}
               
            </ul>
        </>
    );
}

export default Navigation;
