import Axios from 'axios';
import getConfig from './../Config/config'

async function JobsData() {
    var site = getConfig()[0];
    var callData = [{
        "limit": 10,
        "page": 1
    }];
/*
    var res = null;
    const getWrenchBoardJobs = () => {
        Axios.post("https://dashboard.wrenchboard.com/svs/user/startjoblist", callData).then((response) => {
            res = response;
            return response;
        });
    }
*/
    let response = await Axios.post(process.env.REACT_APP_AUX_ENDPOINT+'/startjoblist', callData);
    return await response;
}

export default JobsData;


