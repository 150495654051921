import React, { useEffect, useState } from "react";

function CustomSlider({ images, speed, indicatorColor, indicatorClass }) {
  let [sliderCount, setSliderCount] = useState(0);

  const sliderStart = (count) => {
    if (count + 1 && typeof count == "number") {
      return setSliderCount(count);
    }
    if (sliderCount >= images.length - 1) {
      return setSliderCount(0);
    }
    setSliderCount((prev) => prev + 1);
  };

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      sliderStart();
    }, speed * 1000);
    return () => {
      clearInterval(sliderInterval);
    };
  }, [sliderCount]);

  return (
    <div
      className=""
      style={{
        width: "100%",
        margin: "auto",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div className="" style={{ width: "100%", display: "flex" }}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt="image"
            style={{
              width: "100%",
              minWidth: "100%",
              maxHeight: "695px",
              height: "100%",
              position: "relative",
              transition: ".9s",
              right: `${
                sliderCount == index ? sliderCount * 100 + "%" : "-100%"
              }`,
              opacity: `${sliderCount == index ? "1" : "0"}`,
            }}
            // style={{minWidth:'100%', height:'auto', position:'relative', transition:'.9s', right:`${sliderCount*100}%`, opacity:`${sliderCount == index ? '1':'0'}`}}
          />
        ))}
      </div>
      <div
        className="custom_indicators"
        style={{
          margin: "10px auto",
          display: "flex",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            onClick={() => sliderStart(index)}
            className={`custom_indicator ${indicatorClass}`}
            style={{
              backgroundColor: `${
                sliderCount == index ? `${indicatorColor}` : ""
              }`,
              width: "15px",
              height: "15px",
              borderRadius: "999px",
              border: `1px solid ${indicatorColor}`,
              cursor: "pointer",
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default CustomSlider;
