import React, { useEffect, useState } from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import BlogHomeOne from './BlogHomeOne';
import FaqHomeOne from './FaqHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
//import PricingHomeOne from './PricingHomeOne';
import ProjectHomeOne from './ProjectHomeOne';
import ServicesHomeOne from './ServicesHomeOne';
import TeamHomeOne from './TeamHomeOne';
import TestimonialHomeOne from './TestimonialHomeOne';
//import TrafficHomeOne from './TrafficHomeOne';
import TrafficHomeTwo from './TrafficHomeTwo';
import WrenchBoardHome from './WrenchBoardHome';
import RecentJobsOne from './RecentJobsOne';
import HomeNigeria from "./HomeNigeria";
import FeaturedScreen from './FeaturedScreen';
import { SocketValues } from '../../Contexts/SocketIOContext';
import JobsData from '../../Services/JobsData';
//import BlogData from './../../Services/BlogData';

function HomeOne() {

    let {updateMarket} = SocketValues() // destructure FROM SOCKET

    const [drawer, drawerAction] = useToggle(false);

    let [jobs, setJobs] = useState([])

    useEffect(()=>{
        JobsData().then(res => {
            setJobs(res.data.result_list);
        }).catch(err => {
            console.log('startjoblist error', err)
        })
    },[updateMarket])

    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HomeOneHeader action={drawerAction.toggle} />
            <HomeNigeria jobs={jobs} />
            {/*<HeroHomeOne />*/}
            <RecentJobsOne jobs={jobs} />
            <ServicesHomeOne />
            <FeaturesHomeOne />
            {/*<FaqHomeOne />*/}
            {/*<TrafficHomeOne />*/}
            <TrafficHomeTwo />
            <FeaturedScreen />
            {/*<TestimonialHomeOne />*/}
            {/*<TeamHomeOne />*/}
            {/*<PricingHomeOne />*/}
            <BlogHomeOne />
            {/*<ProjectHomeOne />*/}
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
