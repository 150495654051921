import React from 'react';

import Design from '../../assets/images/value-design.png'
import Approach from '../../assets/images/value-approach.png'
import Interactive from '../../assets/images/value-interactive.png'
import Mobility from '../../assets/images/value-mobility.png'
import Privacy from '../../assets/images/value-privacy.png'
import Seamless from '../../assets/images/value-seamless.png'





function ServicesAbout() {
    return (
        <>
            <section className="appie-services-2-area pt-90 pb-55" id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-12 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">We’re driven by our values</h3>
                                <p>The app provides design and digital marketing. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-tv"></i>
                                    </div> */}
                                    <img src={Design} />
                                </div>
                                <h4 className="title">Carefully designed</h4>
                                <p>We listen to how the users will love to use our tools. It is always about the users</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-code"></i>
                                    </div> */}
                                    <img src={Approach} />
                                </div>
                                <h4 className="title">Modern Approach</h4>
                                <p>Our target is to present a cutting-edge solution that serves the users at all times.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-user-friends"></i>
                                    </div> */}
                                    <img src={Interactive} />
                                </div>
                                <h4 className="title">User Interactive</h4>
                                <p>Email, notifications, and alerts to get you engaged in the process.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-mobile"></i>
                                    </div> */}
                                    <img src={Mobility} />
                                </div>
                                <h4 className="title">Mobility Approach</h4>
                                <p>Web and native app solution for your continuously moving life.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-retweet"></i>
                                    </div> */}
                                    <img src={Seamless} />
                                </div>
                                <h4 className="title">Seamless Sync</h4>
                                <p> Changes across platforms is simplified and streamlined.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 appie-single-service-about item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon d-flex justify-content-center align-items-center">
                                    {/* <div className='container'>
                                        <i className="fal fa-bell"></i>
                                    </div> */}
                                    <img src={Privacy} />
                                </div>
                                <h4 className="title">Privacy</h4>
                                <p>We truly respect your need for privacy. Our solution doesn't need to share your data to work for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ServicesAbout;
