import React, { useEffect } from 'react';
import useToggle from '../../Hooks/useToggle';
import Drawer from '../Mobile/Drawer';
import FooterHomeOne from '../HomeOne/FooterHomeOne';
import BackToTop from '../BackToTop';
import StickyMenu from '../../lib/StickyMenu';
import FAQService from '../Service/FAQServices';
import HeroNews from '../News/HeroNews';
import HeaderNews from '../News/HeaderNews';


function FAQ() {
    useEffect(() => {
        StickyMenu();
    });
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <HeaderNews action={drawerAction.toggle} />
            <HeroNews
                title="Frequently asked questions"
                breadcrumb={[
                    { link: '/', title: 'home' },
                    { link: '/faq', title: 'Faq' },
                ]}
            />
            <FAQService />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default FAQ;