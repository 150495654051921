import React from 'react';
import thumb from '../../assets/images/PerformingTaskNew.png';
import getConfig from './../../Config/config'

function TrafficHomeOne() {
    var site = getConfig()[0];
    return (
        <section className="appie-traffic-area pt-140 pb-180 bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="appie-traffic-title">
                            <h3 className="title">Performing task.</h3>
                            <p>
                                Performing task on WrenchBoard is easy.All you need is a free account and follow the steps below.
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Create free account</h5>
                                    <p> To publish your skills to be hired. Your ratings will grow with performance.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-2 mb-30">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Find or Accept job</h5>
                                    <p> Offers to get hired or search in the marketplace for your skill match.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-3">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Complete the task professionally</h5>
                                    <p> Deliver client specification, communicate with instant messaging.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="appie-traffic-service item-4">
                                    <div className="icon">
                                        <i className="fal fa-check" />
                                    </div>
                                    <h5 className="title">Get Pay for your skills put to use </h5>
                                    <p>Get your account credited when the job is completed, and the client authorizes payment.</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-12">
                                <div className="traffic-btn mt-50">
                                    <a className="main-btn" href={process.env.REACT_APP_DASH_URL_LOGIN}>
                                        Login <i className="fal fa-arrow-right" />
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="traffic-thumb ">
                <img
                    className="wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="200ms"
                    src={thumb}
                    alt=""
                />
            </div>
        </section>
    );
}

export default TrafficHomeOne;
