import React from 'react'
import OwlCarousel from 'react-owl-carousel'
import localImgLoad from '../../lib/localImgLoad'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

export default function FeaturedScreen() {
  
    const screen_slider = {
        loop:true,
        margin:10,
        nav:false,
        autoplay: true,
        smartSpeed: 1500,
        center: true,
        dots: true, 
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            800:{
                items:3
            },
            1200:{
                items:4
            },
            1400:{
                items:5
            }
        }
    }
  return (
    <>
        <section className="appie-service-area pt-50 pb-50 interface_section">
            <div className="container-fluid">
                <div className="appie-traffic-title section_title text-center" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" style={{padding: '0'}}>
                    <h3 className='title'>App Screens <div className='section_sub_title'></div></h3>
                    <p>Simplicity of use is our goal always. Here are some of our featured screens.</p>
                </div>
                <div className="screen_slider" >
                    <OwlCarousel id="screen_slider" {...screen_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_1.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_1.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_2.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_2.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_3.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_3.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_4.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_6.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_5.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_4.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_7.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_7.png')} alt="image" />
                            </div>
                        </div>
                        <div className="item">
                            <div className="screen_frame_img">
                                {/* <img src={localImgLoad('images/slider/slider_7.png')} alt="image" /> */}
                                <img src={localImgLoad('images/slider/app_sliders/screen_5.png')} alt="image" />
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
        </section>
    </>
  )
}
