import React from 'react';

function HeroAbout() {
    return (
        <>
            <div className="appie-about-top-title-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="appie-about-top-title">
                                <h2 className="title">Our team is here to support you.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="appie-about-page-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-about-page-content">
                                <h3 className="title">
                                    Online Marketplace Platform to Get Paid.
                                </h3>
                                <p>
                                WrenchBoard is the marketplace that connects independent talent with businesses that require their skill set and get paid. 
                                We serve everyone from one-person startups to a powerful, trust-driven platform that facilitates businesses and freelancers to work concurrently in unique patterns that unlock their potential. 
                                Our platform provides a range of skills in categories including consulting, finance & accounting, website & app development, creative & design, customer support and operations. Learn more at www.wrenchBoard.com
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroAbout;
