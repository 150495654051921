import React from 'react'
import heroThumbOne from '../../assets/images/app-thumb-1.png';
import heroThumbOne1 from '../../assets/images/app-thumb-2.png';

import heroThumbTwo from '../../assets/images/app-pic.png';
import CustomSlider from '../customSlider/CustomSlider';

const AppDownloadDetails = () => {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="app-download appie-hero-content appie-hero-content-6">
                                <h5 className='title'>Download from</h5>
                            <div className='flex app-download options'>
                                <ul>
                                    <li className=''>
                                        <a className="item-2" target='_blank' href={process.env.REACT_APP_APPLE_APP}>
                                            <i className="fab fa-apple"></i>
                                            <span>
                                                Available on the <span>App Store</span>
                                            </span>
                                        </a>
                                    </li>
                                    <li className=''>
                                        <a className="item-2" target='_blank' href={process.env.REACT_APP_ANDROID_APP}>
                                            <i className="fab fa-google-play"></i>
                                            <span>
                                                Available on the <span>Google Play</span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                                <h5>OR</h5>
                                <a target='_blank' href={process.env.REACT_APP_DASH_URL_SIGNUP} className='main-btn'>
                                    Register Online
                                </a>
                            </div>
                                
                                <h1 className="appie-title"> Turn Chores into Exciting Challenges and Earn <span className='earn-rewards px-2'>Rewards!</span> </h1>
                                <p>Your place to set family goals and reward achievements. Find tasks to earn from, or build a tasks portfolio and find others to perform tasks for you.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="appie-hero-thumb">
                                <div
                                    className="thumb wow animated fadeInUp"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="200ms"
                                >
                                    {/* <img src={heroThumbOne} alt="WrenchBoard" /> */}
                                    <div style={{ width: '350px', margin: 'auto' }}>
                                        <CustomSlider
                                            images={[heroThumbOne, heroThumbOne1, heroThumbOne]}
                                            speed='5'
                                            indicatorColor='#333'
                                        />
                                    </div>
                                </div>
                                <div
                                    className="thumb-2 wow animated fadeInRight"
                                    data-wow-duration="2000ms"
                                    data-wow-delay="600ms"
                                >
                                    <img src={heroThumbTwo} alt="" width="100%" height="100%" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppDownloadDetails