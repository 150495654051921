import Axios from 'axios';
import getConfig from './../Config/config'

async function FaqData() {
    // debugger;
    var site = getConfig()[0];
    let response = await Axios.post(`${process.env.REACT_APP_AUX_ENDPOINT}/faq`);
    return await response;
}

export default FaqData;