import HomeOneHeader from '../HomeOne/HomeOneHeader';
import AppDownloadDetails from './AppDownloadDetails';

export default function AppDownload() {
  return (
    <>
      <HomeOneHeader showLogoOnly={true} />
      <AppDownloadDetails />
    </>
  )
}
