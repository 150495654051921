import { useEffect } from 'react';
import Routes from './Routes';
import { Redirect, useLocation } from 'react-router-dom';

import { SocketValues } from './Contexts/SocketIOContext';

function App() {
    let { joinRoom } = SocketValues() // function to join market room, to be able to receive market job post update

    const {pathname} = useLocation()

    useEffect(()=>{ // sends an event to the socket to enable user join a room to be able to receive update when jobs enters the market
        joinRoom('full-markets-jobs')
    },[])

    return (
        <>
        {pathname.startsWith('/@') ? 
        <Redirect to='/app' />
        :
        <Routes />
        }
        </>
    );
}

export default App;
